import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CreditComponent } from 'src/app/components/reusable/credit/credit.component';

@NgModule({
  declarations: [
    CreditComponent
  ],
  imports: [
    CommonModule
  ],
  exports:[
    CreditComponent
  ]
})
export class CreditModule { }
