<div class="modal-header" *ngIf="videoSource">
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <i class="fas fa-times"></i>
    </button>
</div>

<div class="modal-body" style="width: 100%;" *ngIf="videoSource">

    <video *ngIf="videoSource" width="600" height="300" controls [src]="videoSource">
        Your browser does not support the video tag.
    </video>
</div>