<div class="modal-header">
  <h4 class="modal-title">Update Password</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <i class="fas fa-times"></i>
  </button>
</div>
<div class="modal-body" style="width: 100%;">
  <form name="updatePasswordForm" (ngSubmit)="updatePassword(updatePasswordForm.form)" #updatePasswordForm="ngForm">
    <div class="form-group col-sm-12 pos-relative">
      <div>
        <label for="password" class="col-sm-4">New Password<span style="color: red;">*</span></label>
        <input class="form-input col-sm-8 mb0" [type]="show_eye ? 'text' : 'password'" [(ngModel)]="user.password"
          name="password" required minlength="6" />
      </div>
      <div class="_dsp_flx_inputrow">
        <label class="col-sm-4"></label>
        <div class="col-sm-8" *ngIf="updatePasswordForm.submitted">
          <div *ngIf="updatePasswordForm.controls?.['password']?.['errors']?.['required']"
            class="text text-danger mt-1">
            Please enter new password
          </div>
          <div *ngIf="updatePasswordForm.controls?.['password']?.['errors']?.['minlength']"
            class="text text-danger mt-1">
            Password should have atleast six characters
          </div>
        </div>
      </div>
      <div class="_gen_btn">
        <a (click)="generatePassword()" class="btn btn-primary">
          <i class="fas fa-user-lock"></i> Generate
        </a>
      </div>


      <img class="show-hide-icon" [src]="
      show_eye
        ? 'assets/img/svg/eye-slash.svg'
        : 'assets/img/svg/eye.svg'
    " id="img_2" [title]="show_eye ? 'hide characters' : 'show characters'" (click)="show_eye = !show_eye" />
    </div>

    <fieldset class="col-sm-12 mb20">
      <div class="form-group mb10">
        <input class="mr5" type="checkbox" name="login_info" [(ngModel)]="user.send_info" />
        <label><b>Send Login Information</b></label>
      </div>

      <div *ngIf="user.send_info" class="row" style="justify-content: space-between;">
        <div class="col-sm-8">
          <div>
            <div class="form-group">
              <label><b>Subject</b><span style="color: red;">*</span></label>
            </div>
            <label class="form-label-wrapper">
              <input class="form-input" placeholder="Subject" type="text" name="subject"
                [(ngModel)]="user.template.subject" #subject="ngModel"
                (click)="cursorFocus($event);focusedEle='subject'" [required]="user.send_info" />
            </label>
            <div *ngIf="updatePasswordForm.submitted">
              <div *ngIf="updatePasswordForm.controls?.['subject']?.['errors']?.['required']"
                class="text text-danger mt-1">
                Email subject is required
              </div>
            </div>
          </div>
          <div>
            <div class="form-group">
              <label><b>Body Content</b><span style="color: red;">*</span></label>
            </div>
            <ckeditor [editor]="Editor" name="body" [(ngModel)]="user.template.email_body" id="editor"
              (ready)="onEditorReady($event)" (click)="focusedEle = 'body'" [required]="user.send_info"></ckeditor>
            <div *ngIf="updatePasswordForm.submitted">
              <div *ngIf="updatePasswordForm.controls?.['body']?.['errors']?.['required']"
                class="text text-danger mt-1">
                Email body is required
              </div>
            </div>
          </div>
        </div>

        <div class="col-sm-4">
          <app-merge-fields type="company" (token)="mergeTokens($event)" (templateTokens)="templateTokens($event)"></app-merge-fields>
        </div>
      </div>
    </fieldset>

    <app-spinner></app-spinner>

    <div class="modal-footer">
      <button type="submit" class="btn btn-primary">Submit</button>
      <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">
        Close
      </button>
    </div>
  </form>
</div>