import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CommonService } from '../services/common.service';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivate {

  constructor(private commonService: CommonService) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {


    let user = JSON.parse(localStorage.getItem("user") || '{"data":{"role":0,"payment_status":0}}');

    let { role: userRole, payment_status, modules_access, admin_id } = user.data; // get the role saved in localstorage

    const { agency_access, whitelabel_access, number_access } = modules_access

    const { role, accessTo } = route.data

    if (!role.includes(userRole)) {
      this.commonService.navigateTo("login");
      return false;
    }

    if (!payment_status) { // if payment is failed

      if (admin_id) { // if role is admin, then allow access for user routes
        return true;
      }

      const routeArr = state.url.split("/")
      const currentState = routeArr[routeArr.length - 1]

      if (currentState != "billing") {
        this.commonService.navigateTo("billing")
        return false;
      }
    }

    switch (accessTo) {

      case "company":

        if (!agency_access) {
          window.open(environment.sales_url.agency)
          return false;
        }
        break;

      case "domain":

        if (!whitelabel_access) {
          window.open(environment.sales_url.whitelabel)
          return false;
        }
        break;

      case "number":

        if (!number_access) {
          window.open(environment.sales_url.tracking_number)
          return false;
        }
        break;
    }

    return true;
  }
}
