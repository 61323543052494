import { Component, Injectable, Input, OnInit } from '@angular/core';
import { HttpService } from 'src/app/services/http.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { CommonService } from 'src/app/services/common.service';
import { LocalData } from 'src/app/interfaces/Localdata';
import { Subscription } from 'rxjs';
import { io } from "socket.io-client";
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.css']
})

@Injectable({
  providedIn: 'root'
})
export class NotificationComponent implements OnInit {

  @Input() notificationPopup = { isVisible: false };
  height: string = "0px";
  opacity: number = 0;
  notifications = null;
  start: number = 0; limit: number = 10;
  @Input() notification = { unread: 0 }
  localData: LocalData;
  subscriptions: any = [];

  constructor(private httpService: HttpService, private spinnerService: SpinnerService, private commonService: CommonService) {

    this.localData = this.commonService.localData

    const socket = io(`${environment.backend_url}?id=${this.localData.id}`,{
      transports: ['websocket'],
    });
    const _this = this;

    socket.on('connected', function (socket_id: any) {
      socket.on(`notification_${socket_id}`, function (data: any) {
        const { is_new_notification } = data
        if (is_new_notification) {
          _this.notification.unread++
        }
      });
    });

    this.subscriptions.push(this.commonService.subject.localData.subscribe(data => {
      this.notification = data.notification
    }))
  }

  ngOnInit(): void { }

  navigateNotification = (notificationObj: any) => {

    switch (notificationObj.type) {
      case 1: // Notification of type review
        this.commonService.navigateTo("review")
        break;
    }
  }

  getUserNotifications = () => {

    if (this.notificationPopup.isVisible) {
      this.height = "auto";
      this.opacity = 1;
      this.spinnerService.showSpinner();
      this.httpService.getRequestWithParameters("notification/notifications", { start: this.start, limit: this.limit })
        .subscribe((res: any) => {
          this.spinnerService.hideSpinner();
          if (res.status == 200) {
            this.notifications = res.data
            this.notificationPopup.isVisible = true;
            this.notification = { unread: 0 };

            this.localData = { ...this.localData, notification: { unread: 0, total: 0 } }
            this.commonService.subject.localData.next(this.localData)
          }
        }, error => {
          this.spinnerService.hideSpinner();
          this.spinnerService.errorSwal(error.error);
        })
    } else {
      this.height = "0px";
      this.opacity = 0;
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.map((subscription: Subscription) => subscription.unsubscribe())
  }
}
