import { Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[appLabel]'
})
export class LabelDirective {

  @Input() appLabel: string = "";
  constructor(private el: ElementRef) { }

  ngAfterViewInit() {

    let css = `
    border-radius: 5px;
    padding: 3px 8px;
    font-size: 13px;
    color: #fff;`

    switch (this.appLabel) {

      case "success":
        css += `
        background-color: #27c24c;`;
        break;

      case "warning":
        css += `
          background-color: #fba648;`;
        break;

        case "primary":
          css += `
            background-color: #00acd8;`;
          break;

      case "error":
        css += `
            background-color: red;`;
        break;
    }
    this.el.nativeElement?.setAttribute("style", css);
  }
}
