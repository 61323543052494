import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ReplaySubject } from 'rxjs';
import { LocalData } from '../interfaces/Localdata';
import { SessionData } from '../interfaces/SessionData';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  subject = {
    localData: new ReplaySubject<LocalData>(),
    sessionData: new ReplaySubject<SessionData>(),
  }

  localData = {} as LocalData;
  sessionData = {} as SessionData;

  constructor(private router: Router) {

    this.subject.localData.subscribe(data => {
      this.localData = data;
      localStorage.setItem("user", JSON.stringify({
        data,
      }))
    })

    this.subject.sessionData.subscribe(data => {
      this.sessionData = data;
      sessionStorage.setItem("whitelabel", JSON.stringify({
        data,
      }))
    })

  }

  navigateTo = (route: string, withDelay: boolean = false, timeOut: number = 1500) => {

    let url: string = "";
    let localData = this.localData;

    switch (route) {

      case "login":
        url = '/auth/login';
        break;

      case "dashboard":
        url = localData.role == 1 ? '/app/admin/dashboard' : localData.role == 2 ? '/app/user/dashboard' : '/app/company/dashboard';
        break;

      case "profile":
        url = localData.role == 2 ? '/app/user/profile' : localData.role == 3 ? '/app/company/profile' : '';
        break;

      case "email_templates":
        url = '/app/admin/email-templates'
        break;

      case "review_templates":
        url = [2, 3].includes(localData.role) ? '/app/review/templates' : '/app/admin/review-templates'
        break;

      case "view_companies":
        url = '/app/company/companies';
        break;

      case "view_business":
        url = '/app/business/businesses';
        break;

      case "view_numbers":
        url = '/app/number/numbers';
        break;

      case "buy_number":
        url = '/app/number/buy-number';
        break;

      case "view_product":
        url = '/app/business/products';
        break;

      case "view_customers":
        url = '/app/client/clients';
        break;

      case "view_lists":
        url = '/app/list/lists';
        break;

      case "view_plans":
        url = '/app/admin/plans';
        break;

      case "review":
        url = '/app/review/reviews/0';
        break;

      case "domains":
        url = '/app/domain/domains';
        break;

      case "billing":
        url = '/app/user/billing'
        break;

      case "pages":
        url = '/app/admin/all-pages'
        break;

      case "versions":
        url = '/app/application/latest-updates'
        break;

      case "campaigns":
        url = '/app/review/campaigns'
        break;
    }

    if (withDelay) {
      setTimeout(() => {
        this.router.navigateByUrl(url);
      }, timeOut);
    } else {
      this.router.navigateByUrl(url);
    }
  }

  getToken() {
    if (localStorage.getItem('token')) {
      let tok = localStorage.getItem('token');
      return tok;
    } else {
      return 0;
    }
  }

  formatPhone(countryCode: string, phoneNumber: number) {

    switch (countryCode) {
      case "+1":
      default:
        return `(${phoneNumber.toString().slice(0, 3)}) ${phoneNumber.toString().slice(3, 6)}-${phoneNumber.toString().slice(6, 10)}`
        break;

      case "+44":
        return `${phoneNumber.toString().slice(0, 3)} ${phoneNumber.toString().slice(3, 6)} ${phoneNumber.toString().slice(6, 10)}`
        break;

      case "+61":
        return `${phoneNumber.toString().slice(0, 2)} ${phoneNumber.toString().slice(2, 6)} ${phoneNumber.toString().slice(6, 10)}`
        break;

    }
  }

  generateRandomString(length: number) {
    const characters = '@#$&*()!abcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result.trim();
  }

  checkFormValid = (form: FormGroup) => {

    if (form.invalid) {
      const invalidControl = document.querySelector('.ng-invalid');
      if (invalidControl) {
        invalidControl.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }

      return false;
    }
    return true;
  }

  compileTokens = async (message: string, tokens: any, tokenValues: any) => {
    tokens.map((token: any) => {
      message = message.split(token.name).join(tokenValues[token.value])
    })

    return message;
  }

}
