import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TokenService } from 'src/app/services/token.service';

@Component({
  selector: 'app-merge-fields',
  templateUrl: './merge-fields.component.html',
  styleUrls: ['./merge-fields.component.css']
})
export class MergeFieldsComponent implements AfterViewInit {

  tokens = null;
  @Input() type: string = "all";
  @Output() token = new EventEmitter<object>();
  @Output() templateTokens = new EventEmitter<object>();

  constructor(private tokenService: TokenService) { }

  ngAfterViewInit(): void {
    if (this.tokens == null) {
      this.getTokens();
    }
  }

  getTokens = () => {

    this.tokenService.getTokens({ type: this.type });
    this.tokenService.response.subscribe(tokens => {
      this.tokens = tokens;
      this.templateTokens.emit({ tokens })
    })
  }

  mergeToken = (token: string) => {
    this.token.emit({ token })
  }
}
