import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReviewSliderComponent } from 'src/app/components/reusable/review-slider/review-slider.component';
import { NgbCarouselModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedComponentsModule } from '../shared-components/shared-components.module';

@NgModule({
  declarations: [
    ReviewSliderComponent
  ],
  imports: [
    SharedComponentsModule,
    NgbModule,
    CommonModule,
    NgbCarouselModule
  ],
  exports: [
    NgbCarouselModule,
    ReviewSliderComponent
  ]
})
export class NgCarouselModule { }
