import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sand-timer',
  templateUrl: './sand-timer.component.html',
  styleUrls: ['./sand-timer.component.css']
})
export class SandTimerComponent implements OnInit {

  timeleft: number = 20;
  constructor() { }

  ngOnInit(): void {

    const _this = this;

    let downloadTimer = setInterval(function () {
      _this.timeleft--;
      if (_this.timeleft <= 0)
        _this.timeleft = 20;
    }, 1000);
  }

}
