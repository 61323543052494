import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Whitelabel } from 'src/app/interfaces/Whitelabel';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  subscriptions: any = [];
  supportEmail: string = "";
  whiteLabel = {} as Whitelabel;
  displayFooter: boolean = false;

  constructor(@Inject(DOCUMENT) private document: Document, private commonService: CommonService) {

    this.subscriptions.push(this.commonService.subject.sessionData.subscribe(data => {
      this.whiteLabel = data
    }))
  }

  ngOnInit(): void {
    const docLocation = this.document.location;
    const { pathname } = docLocation;
    if (!pathname.includes("auth/popup")) {
      this.displayFooter = true;
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.map((subscription: Subscription) => subscription.unsubscribe())
  }

  destroy() {
    this.destroy()
  }

}
