import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ValidateEqualModule } from 'ng-validate-equal';
import { ChartModule } from '../chart/chart.module';
import { SpinnerModule } from '../spinner/spinner.module';
import { PaginationModule } from '../pagination/pagination.module';
import { SearchModule } from '../search/search.module';
import { BackModule } from '../back/back.module';
import { CreditModule } from '../credit/credit.module';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { MaterialModule } from '../material/material.module';
import { CountryModule } from '../country/country.module';
import { SharedComponentsModule } from '../shared-components/shared-components.module';
import { NgCarouselModule } from '../ng-carousel/ng-carousel.module';

@NgModule({
  imports: [
    SharedComponentsModule,
    MaterialModule,
    CountryModule,
    CommonModule,
    FormsModule,
    ValidateEqualModule,
    ChartModule,
    SpinnerModule,
    PaginationModule,
    SearchModule,
    BackModule,
    NgCarouselModule
  ],
  exports: [
    SharedComponentsModule,
    ChartModule,
    SpinnerModule,
    PaginationModule,
    SearchModule,
    CommonModule,
    FormsModule,
    ValidateEqualModule,
    NgbModule,
    BackModule,
    CKEditorModule,
    CreditModule,
    MaterialModule,
    CountryModule,
    NgCarouselModule
  ],
})
export class SharedModule { }
