import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { LocalData } from 'src/app/interfaces/Localdata';
import { CommonService } from 'src/app/services/common.service';
import { HttpService } from 'src/app/services/http.service';
import { SpinnerService } from 'src/app/services/spinner.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css'],
})
export class SidebarComponent implements OnInit {

  showClients: boolean = false;
  showSubMenu: boolean = false;
  localData: LocalData;
  modulesAccess = {} as LocalData['modules_access'];
  modulesCompleted = {} as LocalData['modules_completed'];;
  subscriptions: any = [];

  constructor(private commonService: CommonService, private httpService: HttpService, private spinnerService: SpinnerService) {

    this.localData = this.commonService.localData

    this.subscriptions.push(this.commonService.subject.localData.subscribe(data => {
      this.localData = data
      this.modulesAccess = data.modules_access
      this.modulesCompleted = data.modules_completed
    }))
  }

  ngOnInit(): void { }

  routerLink = (type: string) => {

    let routeArr = [
      {
        key: "dashboard",
        values: {
          1: "/app/admin/dashboard",
          2: "/app/user/dashboard",
          3: "/app/company/dashboard",
        },
      },
      {
        key: "review_templates",
        values: {
          1: "/app/admin/review-templates",
          2: "/app/review/templates",
          3: "/app/review/templates",
        },
      },
    ];

    let route: any = routeArr.find((route) => {
      return route.key == type
    });

    return route.values[this.localData.role];
  }

  logout = () => {
    this.spinnerService.showSpinner();
    this.httpService.getRequest("app/sign-out").subscribe((res) => {
      this.spinnerService.hideSpinner();
      if (res.status === 200) {
        localStorage.clear();
        this.commonService.navigateTo("login")
      }
    }, error => {
      this.spinnerService.hideSpinner();
      this.spinnerService.errorSwal(error.error);
    })
  }

  ngOnDestroy(): void {
    this.subscriptions.map((subscription: Subscription) => subscription.unsubscribe())
  }

}
