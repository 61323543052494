import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatphone'
})
export class FormatphonePipe implements PipeTransform {

  transform(number: number, ...args: unknown[]): string {

    let [countryCode] = args;

    switch (countryCode) {

      case "+1":
      default:
        return `${number.toString().slice(0, 3)}-${number.toString().slice(3, 6)}-${number.toString().slice(6, 10)}`
        break;

      case "+44":
        return `${number.toString().slice(0, 3)}-${number.toString().slice(3, 6)}-${number.toString().slice(6, 10)}`
        break;

      case "+61":
        return `${number.toString().slice(0, 2)}-${number.toString().slice(2, 6)}-${number.toString().slice(6, 10)}`
        break;
    }
  }

}
