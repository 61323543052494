import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { SpinnerService } from './spinner.service';
import { ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  response = new ReplaySubject<any>();

  constructor(private httpService: HttpService, private spinnerService: SpinnerService) { }

  getTokens = (reqObj: any = {}) => {

    this.spinnerService.showSpinner();
    this.httpService.getRequestWithParameters("app/tokens", reqObj)
      .subscribe((response) => {
        this.spinnerService.hideSpinner();
        this.response.next(response.data)
      }, (error) => {
        this.spinnerService.hideSpinner();
        this.spinnerService.errorSwal(error.error);
      })
  }

}
