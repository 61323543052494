<div class="modal-header">
  <h4 class="modal-title">Add SMS Credits</h4>
  <button
    type="button"
    class="close"
    aria-label="Close"
    (click)="activeModal.dismiss('Cross click')"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form
    name="addCreditForm"
    (ngSubmit)="addCredits(addCreditForm.form)"
    #addCreditForm="ngForm"
  >
    <div class="form-group">
      <label for="credits">Credits</label>
      <input
        type="number"
        class="form-control"
        id="credits"
        [(ngModel)]="user.credits"
        name="credits"
        #credits="ngModel"
        required
      />

      <div *ngIf="addCreditForm.submitted">
        <div
          *ngIf="addCreditForm.controls?.['credits']?.['errors']?.['required']"
          class="text text-danger mt-1"
        >
          Please enter credits
        </div>
      </div>
    </div>

    <div class="form-group" *ngIf="!user.is_added_by_admin">
      <label for="credits"
        >Amount: ${{ user.credits * user.chargePerCredit | formatAmount }}</label
      >
    </div>

    <button type="submit" class="btn btn-primary">Submit</button
    >&nbsp;&nbsp;&nbsp;

    <button
      type="button"
      class="btn btn-outline-dark"
      (click)="activeModal.close('Close click')"
    >
      Close
    </button>
    <app-spinner></app-spinner>
  </form>
</div>
