<div class="page-flex" style="padding-left: 253px" (click)="hideNavBar($event)">
  <div class="main-wrapper">
    <!-- ! Main nav -->
    <nav class="main-nav--bg">
      <div class="container main-nav mb0">
        <!-- when admin login to user account -->
        <div class="_u_id_container" *ngIf="localData.admin_id && localData.admin_id != 0">
          <label><b>Name:</b> </label> &nbsp;{{ localData.name }}
          <label class="ml10"><b>User id:</b> </label>
          &nbsp;{{ localData.user_id }}
          <button class="btn btn-primary ml15 mr15 log_out_btn" (click)="signOutAdmin()">
            Logout
          </button>
        </div>

        <div class="main-nav-end">

          <span class="_menu_new_support" *ngIf="localData.role == 2">
            <a *ngIf="!sessionData.isWhiteLabel" title="Support" (mouseenter)="showSupportSubmenu=true"
              (mouseleave)="showSupportSubmenu=false">
              <span class="icon"><mat-icon>contact_support</mat-icon></span>
              Getting Started
              <ul class="_dropdown_submenu _drp_dwn_menu" *ngIf="showSupportSubmenu">
                <li>
                  <a title="Getting Started"
                    href="https://docs.google.com/document/d/1OELWj4zlhfrHj2p01asjyOAK5L3riqIPgO9nP_q-KSw/edit?usp=sharing"
                    target="_blank">
                    <span class="icon"><mat-icon>book</mat-icon></span>
                    Getting Started
                  </a>
                </li>
                <li>
                  <a title="What's New" routerLink="/app/application/latest-updates">
                    <span class="icon"><mat-icon>new_releases</mat-icon></span>
                    What's New
                  </a>
                </li>
                <li>
                  <a href="https://docs.google.com/document/d/1IsmWojMNFH3w9oOBiU51li2dIANpXZhxCe1Wprcftho/edit?usp=sharing"
                    title="Bonuses" target="_blank">
                    <span class="icon"><mat-icon>card_giftcard</mat-icon></span>
                    Bonuses
                  </a>
                </li>
                <li>
                  <a href="https://join.skype.com/invite/yEpi2e7OGl0J" title="Connect On Skype" target="_blank">
                    <span class="icon"><mat-icon>message</mat-icon></span>
                    Connect On Skype
                  </a>
                </li>
              </ul>
            </a>
            <a routerLink="/app/number/numbers" routerLinkActive="active" title="Numbers">
              <span class="icon"><mat-icon>numbers</mat-icon></span>
              Tracking Numbers
            </a>

            <a *ngIf="!sessionData.isWhiteLabel" title="Domain" routerLink="/app/domain/domains"
              routerLinkActive="active"> <!-- if the domain on application working is not a whitelabel -->
              <span class="icon">
                <mat-icon>language</mat-icon>
              </span>
              White Label Domain
            </a>
          </span>

          <div class="_main_left_menu">
            <app-notification *ngIf="localData.role != 1" [notification]="notification"
              [notificationPopup]=notificationPopup (click)="hideNavBar($event)"></app-notification>
            <div class="nav-user-wrapper" (click)="showSubmenu = !showSubmenu">
              <button class="nav-user-btn" title="My profile" type="button">
                <span class="nav-user-img">
                  <i style="font-size: 22px" class="fa">&#xf007;</i>
                </span>
                <div class="_dropdown_submenu" *ngIf="showSubmenu">
                  <ul>
                    <li>
                      <a [routerLink]="routerLink('profile')" title="Profile">
                        <span class="icon"><mat-icon>account_circle</mat-icon></span>
                        Profile
                      </a>
                    </li>

                    <span *ngIf="localData.role == 2">
                      <li style="border-bottom: 1px solid #ddd;">
                        <a [routerLink]="routerLink('credits')" title="Credits">
                          <span class="icon"><mat-icon>credit_card</mat-icon></span>
                          SMS Credits
                        </a>
                      </li>
                      <li>
                        <a routerLink="/app/user/billing" title="Billing">
                          <span class="icon"><mat-icon>account_balance</mat-icon></span>
                          Billing
                        </a>
                      </li>
                      <li>
                        <a routerLink="/app/user/integrations" title="Integration">
                          <span class="icon"><mat-icon> merge_type</mat-icon></span>
                          Integrations
                        </a>
                      </li>
                    </span>

                    <span *ngIf="localData.role == 1">
                      <li>
                        <a routerLink="/app/admin/plans" title="Plans">
                          <span class="icon"><mat-icon>perm_contact_calendar</mat-icon>
                          </span>
                          Plans
                        </a>
                      </li>
                      <li>
                        <a routerLink="/app/admin/all-users" title="All Users">
                          <span class="icon"><mat-icon>supervised_user_circle</mat-icon>
                          </span>
                          All Users
                        </a>
                      </li>
                      <li>
                        <a routerLink="/app/admin/all-pages" title="All Pages">
                          <span class="icon"><mat-icon>pages</mat-icon>
                          </span>
                          All Pages
                        </a>
                      </li>
                      <li>
                        <a title="All Releases" routerLink="/app/application/latest-updates">
                          <span class="icon"><mat-icon>new_releases</mat-icon></span>
                          All Releases
                        </a>
                      </li>
                    </span>
                  </ul>
                </div>
              </button>
            </div>
          </div>

        </div>
      </div>
    </nav>
    <app-steps-completed *ngIf="localData.role == 2"></app-steps-completed>
  </div>
  <app-sidebar style="position: fixed; top: 0; left: 0"> </app-sidebar>
  <router-outlet></router-outlet>
  <app-spinner></app-spinner>
</div>