import { Component, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Country } from 'src/app/interfaces/Country';
import { CountryService } from 'src/app/services/country/country.service';

@Component({
  selector: 'app-country',
  templateUrl: './country.component.html',
  styleUrls: ['./country.component.css']
})
export class CountryComponent implements OnInit {

  @Input() country: any = {};

  @Input() showCountries = ""
  subscriptions: Subscription[] = [];

  countries: Country[] = [];

  constructor(private countryService: CountryService) { }

  ngOnInit(): void {
    if (!this.country.hasOwnProperty("country_id")) {
      this.country.country_id = "1"
    } else {
      this.country.country_id = this.country?.country_id?.toString()
    }
  }

  ngAfterViewInit() {

    this.countryService.getCountries()

    this.subscriptions.push(this.countryService.countrySubject.subscribe((countries: Country[]) => {
      if (this.showCountries.length > 0) {
        this.countries = countries.filter((country: any) => this.showCountries.split(",").includes(country.iso))
      } else {
        this.countries = countries
      }
    }))
  }

  ngOnDestroy(): void {
    this.subscriptions.map((subscription: Subscription) => subscription.unsubscribe())
  }

}