import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SessionData } from 'src/app/interfaces/SessionData';
import { CommonService } from 'src/app/services/common.service';
import { HttpService } from 'src/app/services/http.service';
import { SpinnerService } from 'src/app/services/spinner.service';

@Component({
  selector: 'app-update-password',
  templateUrl: './update-password.component.html',
  styleUrls: ['./update-password.component.css']
})
export class UpdatePasswordComponent implements OnInit {

  user = {
    user_id: 0,
    name: "",
    email: "",
    password: "",
    type: "user",
    send_info: false,
    template: {
      subject: "",
      email_body: ""
    }
  }
  focusedEle: string = "subject";
  userEmail: string = "";
  tokens: any = [];
  tokenValues: any = [];

  show_eye: boolean = false;
  show_confirm_eye: boolean = false;
  cursorStart: number = 0;
  Editor = ClassicEditor;
  classicEditor: any;
  sessionData:SessionData;

  constructor(public activeModal: NgbActiveModal, private spinnerService: SpinnerService, private httpService: HttpService, private commonService: CommonService) { 
    this.sessionData = this.commonService.sessionData
  }

  ngOnInit(): void {
    this.getTemplate();
  }

  generatePassword = () => {
    this.user.password = this.commonService.generateRandomString(10)
  }

  onEditorReady = (editor: ClassicEditor) => {
    this.classicEditor = editor
  }

  cursorFocus = (event: any) => {
    this.cursorStart = event.target.selectionStart
  }

  templateTokens = async (tokens: any) => {

    this.tokens = tokens.tokens;
    this.tokenValues = { company_name: this.user.name, company_email: this.user.email, company_password: '[agency_password]', login_url: this.sessionData.origin, user_email: this.userEmail };

    let { subject, email_body } = this.user.template;

    this.user.template.subject = await this.commonService.compileTokens(subject, this.tokens, this.tokenValues)

    this.user.template.email_body = await this.commonService.compileTokens(email_body, this.tokens, this.tokenValues)
  }

  mergeTokens = async (event: any) => {

    switch (this.focusedEle) {

      case "subject":

        let subject = this.user.template.subject

        this.user.template.subject = `${subject.slice(0, this.cursorStart)} ${event.token} ${subject.slice(this.cursorStart)}`

        break;

      case "body":

        this.classicEditor.model.change(async (writer: any) => {
          writer.insertText(` ${event.token} `, this.classicEditor.model.document.selection.getFirstPosition());
        })
        break;

    }

    this.cursorStart += event.token.length + 1
  }

  getTemplate = () => {

    this.spinnerService.showSpinner();
    const request = { name: "agency_update_password", type: "email" };
    this.httpService.getRequestWithParameters("template/info", request)
      .subscribe((response) => {
        this.spinnerService.hideSpinner();
        if (response.status == 200) {
          let { subject, body: email_body } = response.data
          this.userEmail = response.data.user.email
          this.user.template = { ...this.user.template, subject, email_body }
        }
      }, (error) => {
        this.spinnerService.hideSpinner();
        this.spinnerService.errorSwal(error.error);
      })

  }

  updatePassword = async (form: FormGroup) => {

    if (this.commonService.checkFormValid(form)) {

      this.spinnerService.showSpinner();
      this.httpService.postRequest(this.user, "password/updatepassword")
        .subscribe((response) => {
          this.spinnerService.hideSpinner();
          if (response.status == 200) {
            this.spinnerService.successSwal(response)
            this.activeModal.close();
          }
        }, (error) => {
          this.spinnerService.hideSpinner();
          this.spinnerService.errorSwal(error.error);
        })
    }
  }
}
