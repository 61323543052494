import { Injectable } from '@angular/core';
import { HttpService } from '../http.service';
import { SpinnerService } from '../spinner.service';
import { AsyncSubject, ReplaySubject } from 'rxjs';
import { Country } from 'src/app/interfaces/Country';

@Injectable({
  providedIn: 'root'
})
export class CountryService {

  countrySubject: ReplaySubject<Country[]> = new ReplaySubject<Country[]>();

  constructor(private httpService: HttpService, private spinnerService: SpinnerService) { }

  getCountries = () => {

    this.spinnerService.showSpinner();
    this.httpService.getRequest("app/countries").
      subscribe((res) => {
        this.spinnerService.hideSpinner();
        this.countrySubject.next(res.data)
      }, error => {
        this.spinnerService.hideSpinner();
        this.spinnerService.errorSwal(error.error);
      })
  }

}
