import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LogoComponent } from 'src/app/components/logo/logo.component';
import { ImageViewerComponent } from 'src/app/components/reusable/image-viewer/image-viewer.component';
import { MergeFieldsComponent } from 'src/app/components/reusable/merge-fields/merge-fields.component';
import { PlayVideoComponent } from 'src/app/components/reusable/play-video/play-video.component';
import { SandTimerComponent } from 'src/app/components/reusable/sand-timer/sand-timer.component';
import { LabelDirective } from 'src/app/directives/label.directive';
import { FormatAmountPipe } from 'src/app/pipes/format-amount.pipe';
import { FormatphonePipe } from 'src/app/pipes/formatphone.pipe';
import { ShortenStringPipe } from 'src/app/pipes/shorten-string.pipe';

@NgModule({
  declarations: [
    LabelDirective,
    FormatphonePipe,
    ShortenStringPipe,
    FormatAmountPipe,
    MergeFieldsComponent,
    LogoComponent,
    PlayVideoComponent,
    SandTimerComponent,
    ImageViewerComponent,
  ],
  imports: [
    CommonModule
  ],
  exports: [
    LabelDirective,
    MergeFieldsComponent,
    PlayVideoComponent,
    SandTimerComponent,
    ImageViewerComponent,
    LogoComponent,
    FormatphonePipe,
    ShortenStringPipe,
    FormatAmountPipe,
  ],
})
export class SharedComponentsModule { }
