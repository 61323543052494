<div class="_white_bx">
    <div class="row">
        <div class="_white_bx col-md-6">
            <h5>Current SMS Credits</h5>
            <h5>{{ credits.current }}</h5>
        </div>
        <div class="_white_bx col-md-6">
            <h5>Credit Used</h5>
            <h5>{{ credits.used }}</h5>
        </div>
        <div class="col-md-12 mt15 text-center" *ngIf="localData.role !=3 "> <!-- if not a company user -->
            <a (click)="openAddCreditModal()" class="btn btn-primary">Buy Credits</a>
        </div>
    </div>
</div>