import { Component, OnInit } from '@angular/core';
import { HttpService } from 'src/app/services/http.service';
import { CommonService } from 'src/app/services/common.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { LocalData } from 'src/app/interfaces/Localdata';
import { Subscription } from 'rxjs';
import { SessionData } from 'src/app/interfaces/SessionData';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.css']
})
export class TopbarComponent implements OnInit {

  notification: any = null;
  localData: LocalData
  modulesAccess: any;
  showSubmenu: boolean = false;
  showSupportSubmenu: boolean = false;
  notificationPopup = { isVisible: false };
  subscriptions: any = [];
  sessionData = {} as SessionData;

  constructor(private httpService: HttpService, private commonService: CommonService, private spinnerService: SpinnerService) {

    this.localData = this.commonService.localData

    this.commonService.subject.sessionData.subscribe((data) => {
      this.sessionData = data
    })

    this.subscriptions.push(this.commonService.subject.localData.subscribe(data => {
      this.localData = data
      this.modulesAccess = this.localData.modules_access
      this.notification = this.localData.notification
    }))

  }

  ngOnInit(): void { }

  routerLink = (type: string) => {

    let routeArr = [
      {
        key: "profile",
        values: {
          1: "/app/admin/profile",
          2: "/app/user/profile",
          3: "/app/company/profile",
        },
      },
      {
        key: "credits",
        values: {
          2: "/app/user/credits",
          3: "/app/company/credits",
        },
      },
    ];

    let route: any = routeArr.find((route) => {
      return route.key == type
    });

    return (typeof route.values == "string") ? route.values : route.values[this.localData.role]
  }

  hideNavBar = (event: any) => {
    if (event.target.className !== "fa")
      this.showSubmenu = false;
    if (event.target.className !== "ng-star-inserted")
      this.notificationPopup.isVisible = false;
  }

  /**
   * signout admin from user account
   */
  signOutAdmin = () => {
    this.spinnerService.showSpinner();
    this.httpService.getRequest("admin/signout-user-admin")
      .subscribe((res) => {
        if (res.status == 200) {

          this.localData = res.data;
          this.commonService.subject.localData.next(res.data)
          this.commonService.navigateTo("dashboard");
        }
      }, (error) => {
        this.spinnerService.hideSpinner();
        this.spinnerService.errorSwal(error.error);
      })
  }

  ngOnDestroy(): void {
    this.subscriptions.map((subscription: Subscription) => subscription.unsubscribe())
  }
}
