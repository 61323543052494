<ngb-carousel *ngIf="reviews!= null && reviews.length >0" #carousel [interval]="3000" [pauseOnHover]="pauseOnHover"
    [pauseOnFocus]="pauseOnFocus" (slide)="onSlide($event)">

    <ng-template *ngFor="let review of reviews" ngbSlide class="col-sm-6">
        <div class="testimonial">
            <div class="_review_list_item">
                <div class="_list_top">
                    <div class="_list_top_left">
                        <span class="_avtar">
                            <img *ngIf="review.customer.image;else showName" src="{{review.customer.image}}">
                            <ng-template #showName>{{review.customer.firstname | uppercase | slice: 0 :
                                1}}</ng-template>
                        </span>
                        <span class="_avtar_name">{{review.customer.firstname}} {{review.customer.lastname}}</span>
                    </div>
                    <span clas="_rating_section">
                        <ngb-rating [max]="5" [(rate)]="review.rating" [readonly]="true">
                            <ng-template let-fill="fill">
                                <span class="star" [ngClass]="'color'" [class.filled]="fill === 100"
                                    [style.width.%]="fill">&#9733;</span>
                            </ng-template>
                        </ngb-rating>
                    </span>
                </div>
                <div class="_list_des" [innerHTML]="review.message | titlecase | shortenString : review.isFullText:400"
                    (click)="review.isFullText = true;">
                </div>
            </div>
        </div>
    </ng-template>
    
</ngb-carousel>