import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonService } from 'src/app/services/common.service';
import { HttpService } from 'src/app/services/http.service';
import { SpinnerService } from 'src/app/services/spinner.service';

@Component({
  selector: 'app-add-credit',
  templateUrl: './add-credit.component.html',
  styleUrls: ['./add-credit.component.css']
})
export class AddCreditComponent implements OnInit {

  user = {
    user_id: 0,
    credits: 10,
    chargePerCredit: 0,
    is_added_by_admin: false
  }

  constructor(public activeModal: NgbActiveModal, private spinnerService: SpinnerService, private httpService: HttpService, private commonService: CommonService) { }

  ngOnInit(): void {
  }

  addCredits = (form: FormGroup) => {

    if (this.commonService.checkFormValid(form)) {

      this.spinnerService.showSpinner();
      this.httpService.postRequest(this.user, "credit/addcredits")
        .subscribe((response) => {
          this.spinnerService.hideSpinner();
          if (response.status == 200) {
            this.activeModal.close();
            this.spinnerService.successSwal(response)
          }
        }, (error) => {
          this.spinnerService.hideSpinner();
          this.spinnerService.errorSwal(error.error);
        })
    }

  }

}
