<div *ngIf="showStep">

    <mat-horizontal-stepper #stepper>

        <mat-step [completed]="modules_completed.has_business >= 1 ? true:false"
            [color]="modules_completed.has_business >= 1?'primary':'warn'">
            <ng-template matStepLabel>BizProduct</ng-template>
        </mat-step>


        <mat-step [completed]="modules_completed.has_contact >= 1 ? true:false"
            [color]="modules_completed.has_contact >= 1?'primary':'warn'">
            <ng-template matStepLabel>Contacts</ng-template>
        </mat-step>

        <mat-step [completed]="modules_completed.has_number >= 1 ? true:false"
            [color]="modules_completed.has_number >= 1?'primary':'warn'">
            <ng-template matStepLabel>Tracking Number</ng-template>
        </mat-step>

        <mat-step [completed]="modules_completed.has_review >= 1 ? true:false"
            [color]="modules_completed.has_review >= 1?'primary':'warn'">
            <ng-template matStepLabel>Ask Review</ng-template>
        </mat-step>

    </mat-horizontal-stepper>
</div><br>