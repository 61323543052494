import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, finalize, tap } from 'rxjs';
import { CommonService } from 'src/app/services/common.service';
import { Router } from '@angular/router';

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {

  constructor(private commonService: CommonService, private router: Router) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    let tok = this.commonService.getToken();
    let user = JSON.parse(localStorage.getItem("user") || '{"data":{"role":0,"admin_id":0}}')
    user = user.data
    const whitelabel = sessionStorage.getItem("whitelabel") || '{"data":{"isWhiteLabel":0,"email":"","logo":""}}'

    let tokenizedReq = request.clone({
      setHeaders: {
        role: String(user.role),
        amid: String(user.admin_id),
        request_mode: JSON.stringify(JSON.parse(whitelabel).data),
        Authorization: `Bearer ${tok}`,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods":
          "GET, POST, PUT, DELETE, PATCH, OPTIONS",
        "Access-Control-Allow-Headers":
          "X-Requested-With, content-type, Authorization",
      },
    });

    return next.handle(tokenizedReq)
      .pipe(
        tap({
          // Succeeds when there is a response; ignore other events
          next: (event) => {
            if (event instanceof HttpResponse) {
              if (event.headers.get("Auth-token") && event.headers.get("Auth-token") != undefined) {
                let token = event.headers.get("Auth-token");
                localStorage.setItem("token", String(token));
              }
            }
          },
          // Operation failed; error is an HttpErrorResponse
          error: (error) => {
            if (error instanceof HttpErrorResponse) {
              if ([401, 403].includes(error.status)) {
                localStorage.clear();
                this.router.navigateByUrl("auth/login"); // if logged in user is user
              }
            }
          }
        }),
      );
  }
}
