import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from "ngx-spinner";

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {

  constructor(private spinnerService: NgxSpinnerService) { }

  public showSpinner() {
    this.spinnerService.show();
  }
  public hideSpinner() {
    this.spinnerService.hide();
  }

  async successSwal(text = { message: "success" }, timer: number = 1500) {

    return await Swal.fire({
      icon: 'success',
      title: text.message,
      showConfirmButton: true,
      confirmButtonColor: "#00acd8",
      timer
    })
  }

  async errorSwal(text = { message: "error" }, timer: number = 5000) {
    return await Swal.fire({
      icon: 'error',
      title: text.message,
      showConfirmButton: true,
      confirmButtonColor:"#00acd8",
      timer
    })
  }

  async infoSwal(text = { message: "info" }, timer: number = 3000) {
    return await Swal.fire({
      icon: 'info',
      title: text.message,
      showConfirmButton: true,
      confirmButtonColor: "#00acd8",
      timer
    })
  }

  async confirmSwal(text: string, title: string = "Confirm") {

    return await Swal.fire({
      title,
      text,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: "#00acd8",
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes'
    })
  }
} 
