<div class="search-main">
    <div class="search-wrapper">
      <i class="fas fa-search"></i>
      <input
        type="text"
        placeholder="{{placeholder}}"
        [(ngModel)]="searchQuery"
        name="searchs"
        (input)="onSearchChange()"
      />
      <a (click)="searchQuery = '';onSearchChange()"><i class="fas fa-times-circle"></i></a>
    </div>
  </div>