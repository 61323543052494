import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatAmount'
})
export class FormatAmountPipe implements PipeTransform {

  transform(value: string | number, ...args: unknown[]): unknown {

    if(value){
      return value.toString().includes(".") ? value : `${value}.00`
    }

    return value
  }

}
