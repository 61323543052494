import { Component, Input, OnInit } from '@angular/core';
import { ChartConfiguration, ChartData, ChartType } from 'chart.js';
import DataLabelsPlugin from 'chartjs-plugin-datalabels';

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.css']
})
export class ChartComponent implements OnInit {

  @Input() data: ChartData<'bar'> = {
    labels: [],
    datasets: [
    ]
  };
  @Input() height: number = 300;
  @Input() type: any = "bar";
  chartType: ChartType = this.type

  constructor() { }

  ngOnInit(): void {
    this.chartType = this.type
    if (this.data.datasets) {
      this.data.datasets.map((data, key) => {
        return data.data.filter((val) => val != 0)
      })
    }
  }

  chartOptions: ChartConfiguration['options'] = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {},
      y: {
        beginAtZero: true,
        ticks: {
          stepSize: 1,
          minRotation: 0
        }
      }
    },
    plugins: {
      legend: {
        display: true,
      },
      datalabels: {
        anchor: 'end',
        align: 'end'
      }
    }
  };

  chartPlugins = [DataLabelsPlugin];

}
