import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { HttpRequestInterceptor } from './interceptor/http-request/http-request.interceptor';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { TopbarComponent } from './components/topbar/topbar.component';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NotificationComponent } from './components/notification/notification.component';
import { UpdatePasswordComponent } from './components/modals/update-password/update-password.component';
import { SharedModule } from './modules/shared/shared.module';
import { StepsCompletedComponent } from './components/reusable/steps-completed/steps-completed.component';
import { FooterComponent } from './components/footer/footer.component';

@NgModule({
  declarations: [
    AppComponent,
    SidebarComponent,
    TopbarComponent,
    NotificationComponent,
    UpdatePasswordComponent,
    StepsCompletedComponent,
    FooterComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    SharedModule,
    AppRoutingModule,
    HttpClientModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS, useClass: HttpRequestInterceptor, multi: true,
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
