import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TopbarComponent } from './components/topbar/topbar.component';
import { AuthGuard } from './guards/auth.guard';
import { RoleGuard } from './guards/role.guard';
import { CommonModule } from '@angular/common';

const routes: Routes = [
  {
    path: "",
    redirectTo: "auth/login",
    pathMatch: "full"
  },
  {
    path: "pub", loadChildren: () => import('./modules/public/public.module').then(m => m.PublicModule)
  },
  {
    path: "auth", loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: "app",
    canActivate: [AuthGuard],
    component: TopbarComponent,
    children: [
      {
        path: "application",
        canActivate: [RoleGuard],
        data: { role: [1, 2], accessTo: "" },
        loadChildren: () => import('./modules/application/application.module').then(m => m.ApplicationModule)
      },
      {
        path: "admin",
        canActivate: [RoleGuard],
        data: { role: [1], accessTo: "admin" },
        loadChildren: () => import('./modules/admin/admin.module').then(m => m.AdminModule)
      },
      {
        path: "company",
        canActivate: [RoleGuard],
        data: { role: [2, 3], accessTo: "company" },
        loadChildren: () => import('./modules/company/company.module').then(m => m.CompanyModule)
      },
      {
        path: "user",
        canActivate: [RoleGuard],
        data: { role: [2], accessTo: "user" },
        loadChildren: () => import('./modules/user/user.module').then(m => m.UserModule)
      },
      {
        path: "client",
        canActivate: [RoleGuard],
        data: { role: [1, 2, 3], accessTo: "client" },
        loadChildren: () => import('./modules/customer/customer.module').then(m => m.CustomerModule)
      },
      {
        path: "business",
        canActivate: [RoleGuard],
        data: { role: [1, 2, 3], accessTo: "business" },
        loadChildren: () => import('./modules/store/store.module').then(m => m.StoreModule)
      },
      {
        path: "notification",
        canActivate: [RoleGuard],
        data: { role: [1, 2, 3], accessTo: "notification" },
        loadChildren: () => import('./modules/notification/notification.module').then(m => m.NotificationModule)
      },
      {
        path: "review",
        canActivate: [RoleGuard],
        data: { role: [1, 2, 3], accessTo: "review" },
        loadChildren: () => import('./modules/review/review.module').then(m => m.ReviewModule)
      },
      {
        path: "list",
        canActivate: [RoleGuard],
        data: { role: [2, 3], accessTo: "list" },
        loadChildren: () => import('./modules/list/list.module').then(m => m.ListModule)
      },
      {
        path: "number",
        canActivate: [RoleGuard],
        data: { role: [2, 3], accessTo: "number" },
        loadChildren: () => import('./modules/number/number.module').then(m => m.NumberModule)
      },
      {
        path: "domain",
        canActivate: [RoleGuard],
        data: { role: [2], accessTo: "domain" },
        loadChildren: () => import('./modules/domain/domain.module').then(m => m.DomainModule)
      }
    ],
  },
  {
    path: "**",
    redirectTo: "auth/login",
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
    CommonModule
  ],
  exports: [RouterModule],
})

export class AppRoutingModule { }
