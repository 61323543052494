import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  Api_url: string = `${environment.backend_url}/api/`

  constructor(private http: HttpClient) { }

  objectToString(obj: any) {
    let str = "";

    Object.keys(obj).forEach((ele) => {

      if (obj[ele] != '' && !isNaN(obj[ele])) {
        obj[ele] = Number(obj[ele]);
      }
      str += `${ele}=${obj[ele]}&`;
    })
    return str;
  }

  payloadConversion = (data: any) => {

    Object.keys(data).forEach((ele) => {

      if (data[ele].length > 0 && !isNaN(data[ele])) {
        data[ele] = Number(data[ele]);
      }
    })
    return data;
  }

  postRequest(data: object, path: string): Observable<any> {
    return this.http.post(this.Api_url + path, data);
  }

  putRequest(data: object, path: string): Observable<any> {
    return this.http.put(this.Api_url + path, data);
  }

  deleteRequest(data: object, path: string): Observable<any> {
    return this.http.delete(`${this.Api_url}${path}?${this.objectToString(data)}`);
  }

  getRequest(url: string): Observable<any> {
    return this.http.get(`${this.Api_url}${url}`);
  }

  getRequestWithParameters(url: string, param: object): Observable<any> {
    return this.http.get(`${this.Api_url}${url}?${this.objectToString(param)}`);
  }
}