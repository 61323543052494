import { AfterViewInit, Component, Renderer2, ViewChild } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { LocalData } from 'src/app/interfaces/Localdata';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-steps-completed',
  templateUrl: './steps-completed.component.html',
  styleUrls: ['./steps-completed.component.css']
})
export class StepsCompletedComponent implements AfterViewInit {

  modules_completed = {} as LocalData['modules_completed']
  showStep: boolean = false;
  submenuActive: string = "";
  subscriptions: any = [];
  url: any = {};

  @ViewChild("stepper") private stepper!: MatStepper;

  constructor(private commonService: CommonService, private router: Router, private renderer: Renderer2) {

    this.renderer.listen('document', 'click', (event) => {
      this.checkSelectedEle(event)
    });

    this.subscriptions.push(this.commonService.subject.localData.subscribe(data => {

      this.modules_completed = data.modules_completed

      if (data.role == 2) { // role is user

        // let { has_business, has_contact, has_number, has_review } = this.modules_completed
        // this.showStep = has_business != 0 && has_contact != 0 && has_number != 0 && has_review != 0 ? false : true

        this.showStep = true

        this.url = {

          "business": this.modules_completed.has_business >= 1 ? '/app/business/businesses' : '/app/business/add-business',

          "contact": this.modules_completed.has_contact >= 1 ? '/app/client/clients' : '/app/client/add-client/0/0',

          "number": this.modules_completed.has_number >= 1 ? '/app/number/numbers' : '/app/number/buy-number'
        }
      }
    }))
  }

  ngAfterViewInit(): void {
    this.stepper['selectedIndex'] = 4
  }

  checkSelectedEle(event: any) {

    const className = event.target['className'];

    if (className && !className.includes("mat-step")) {
      this.stepper['selectedIndex'] = 4
    } else {
      this.navigateToStep(this.stepper)
    }
  }

  navigateToStep = (event: any) => {
    const selectedIndex = event.selectedIndex
    const name = !selectedIndex ? "business" : selectedIndex == 2 ? "number" : "contact"

    if (selectedIndex != 4) {
      this.router.navigateByUrl(this.url[name])
    }

  }

  ngOnDestroy(): void {
    this.subscriptions.map((subscription: Subscription) => subscription.unsubscribe())
  }

}
