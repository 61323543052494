import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { fromEvent, debounceTime, distinctUntilChanged, Subject } from 'rxjs';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})

export class SearchComponent implements OnInit {

  @Input() placeholder:string = "Search";
  @Output() searchChange = new EventEmitter<{ search: string }>();
  private searchSubject = new Subject<string>();
  searchQuery: string = "";

  constructor() {
    this.searchSubject
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe((query: any) => {
        this.searchChange.emit({ search: query })
      });
  }

  ngOnInit(): void {
  }

  onSearchChange = () => {
    this.searchSubject.next(this.searchQuery);
  }

}
