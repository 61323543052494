<aside class="sidebar">
  <div class="sidebar-start">
    <div class="sidebar-head">
      <app-logo></app-logo>
      <!-- <h3>The Review Portal</h3> -->
    </div>
    <div id="leftside-navigation" class="sidebar-body nano">
      <ul class="nano-content sidebar-body-menu">

        <li>
          <a [routerLink]="routerLink('dashboard')" routerLinkActive="active">
            <span class="icon"><mat-icon>dashboard</mat-icon></span>
            Dashboard
          </a>
        </li>

        <span
          *ngIf="localData.role != 1 && (localData.access.add_business == 1 || localData.access.view_business == 1 )">

          <li>
            <a routerLink="/app/business/businesses" routerLinkActive="active">
              <span class="icon"><mat-icon>business</mat-icon></span>
              Businesses
            </a>
          </li>

          <li>
            <a routerLink="/app/business/products" routerLinkActive="active">
              <span class="icon"><mat-icon>business</mat-icon></span>
              Products
            </a>
          </li>

          <li>
            <a routerLink="/app/review/reviews" routerLinkActive="active">
              <span class="icon"><mat-icon>reviews</mat-icon></span>
              Reviews
            </a>
          </li>
        </span>

        <li>
          <a routerLink="/app/review/campaigns" routerLinkActive="active">
            <span class="icon"><mat-icon>campaign</mat-icon></span>
            Campaigns
          </a>
        </li>

        <span
          *ngIf="localData.role != 1 && (localData.access.add_business == 1 || localData.access.view_business == 1 )">
          <li>
            <a routerLink="/app/client/clients" routerLinkActive="active">
              <span class="icon"><mat-icon>contact_page</mat-icon></span>
              Contacts</a>
          </li>
        </span>

        <span *ngIf="localData.role != 1">
          <li>
            <a routerLink="/app/list/lists" routerLinkActive="active" id="step3">
              <span class="icon"><mat-icon>list</mat-icon></span>
              Lists
            </a>
          </li>
        </span>

        <span *ngIf="localData.role == 2">
          <li>
            <a routerLink="/app/company/companies" routerLinkActive="active" id="step1">
              <span class="icon"><mat-icon>supervised_user_circle</mat-icon></span>
              Login Access
            </a>
          </li>
        </span>

        <span *ngIf="localData.role == 1">
          <li>
            <a routerLink="/app/admin/email-templates" routerLinkActive="active">
              <span class="icon"><mat-icon>book</mat-icon></span>
              Email Templates
            </a>
          </li>
        </span>

        <span>
          <li>
            <a [routerLink]="routerLink('review_templates')" routerLinkActive="active">
              <span class="icon"><mat-icon>book</mat-icon></span>
              Review Templates
            </a>
          </li>
        </span>

        <li>
          <a class="show-cat-btn" (click)="logout()">
            <span class="icon"><mat-icon>logout</mat-icon></span>
            Sign-Out
          </a>
        </li>
      </ul>
    </div>
  </div>
</aside>